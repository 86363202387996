import * as React from 'react';

const BookshelfList = ({ books }) => {
  return (
    <ul className="book-list">
      {[].concat(books).map((book, key) => {
        const title = book.title;
        return (
          <li className="book-link" key={key}>
            <a
              href={book.slug}
              style={{ textDecoration: 'none' }}
              rel="noopener noreferrer"
            >
              {title}
              <p className="book-author">{book.author}</p>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default BookshelfList;
